function shuffleArray(array) {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}

const invitationCode = "HR1";

var problemsOptions = [
  'Conflict or communication issues',
  'Betrayal or lack of honesty',
  'Lack of sex',
  'Boredom/loss of excitement or passion',
  'Lack of appreciation',
  // 'Other' => User can type
];

shuffleArray(problemsOptions);

export const questions = [
  {
    tag: 'input',
    type: 'radio',
    name: 'started',
    questionText:
      'Welcome to our psychological research-based relationship strength assessment.&&These questions are based on the journal research paper published by the Psychological Association.&&It will take you about 4 minutes to complete this test.&&Are you ready?',
    options: [`Yes, let's start!`, 'No, not yet.'],
  },
  {
    tag: 'input',
    type: 'email',
    name: 'started',
    questionText: 'No worries - come back when you feel ready. We will be here for you.',
    conditional: 'No, not yet.',
  },
  {
    tag: 'input',
    type: 'radio',
    name: 'invitationCodePreScreen',
    questionText:
      'Great!&&We are currently in private beta and this service is invitation-only.&&Do you have an invitation code?',
    options: ["Yes, got code my here", "Nope, don't have an invite"],
    conditionalName: "started",
    conditional: "Yes, let's start!",
  },
  {
    tag: 'input',
    type: 'email',
    name: 'invitationCodeRequestEmail',
    questionText: "Don't fret! We are accepting more users every day.&&To get an invitation code, simply leave your email address address below and we will let you know.&&(There's a waiting list and it's free.)&&What is your email address?",
    conditionalName: "invitationCodePreScreen",
    conditional: "Nope, don't have an invite",
  },
  {
    tag: 'input',
    type: 'text',
    name: 'invitationCodeEntry',
    questionText:
      'Enter your invitation code and press the arrow button to continue.',
    conditionalName: "invitationCodePreScreen",
    conditional: "Yes, got code my here",
  },
  {
    tag: 'input',
    type: 'text',
    name: 'invitationCodeInvalid',
    questionText:
      'Sorry, I don\'t recognise this code. To get support, reach out to hello@findheartspace.com.',
    conditionalName1: "invitationCodeEntry",
    conditional1: "^(?!.*(" + invitationCode + "))",
    conditionalName2: "invitationCodePreScreen",
    conditional2: "^(?!.*(Nope, don't have an invite))",
  },
  {
    tag: 'input',
    type: 'radio',
    name: 'age',
    questionText:
      'Awesome! Note that your privacy matters to us. Your answers are 100% confidential and anonymous through our secure technology.&&Firstly, can you tell us a bit about yourself?&&This will help our expert system to finetune its analysis.&&How old are you?',
    options: ['17 or younger', '18-25', '26-34', '35-43', '44-55', '56-65', '66 or older'],
    conditionalName: "invitationCodeEntry",
    conditional: invitationCode,
  },
  {
    tag: 'input',
    type: 'radio',
    name: 'gender',
    questionText: 'Your gender?',
    options: [
      'Female',
      'Male',
      'Non-binary',
      'Prefer not to say',
      'Do not know',
      // 'Other' => Custom condition
    ],
    conditionalName: "invitationCodeEntry",
    conditional: invitationCode,
  },
  {
    tag: 'input',
    type: 'radio',
    name: 'sexualOrientation',
    questionText: 'What is your sexual orientation?',
    options: [
      'Heterosexual',
      'Gay / Lesbian',
      'Bisexual',
      'Prefer not to say',
      'Do not know',
      // 'Other' => Custom condition
    ],
    conditionalName: "invitationCodeEntry",
    conditional: invitationCode,
  },
  {
    tag: 'input',
    type: 'radio',
    name: 'relationshipLength',
    questionText: 'And what is the length of your relationship?',
    options: [
      'Not in a romantic relationship',
      'Less than/equal to 1 year',
      'Between 1 year to 3 years',
      'Between 4 years to 9 years',
      'Between 9 years to 15 years',
      'More than 15 years',
      'Prefer not to identify',
    ],
    conditionalName: "invitationCodeEntry",
    conditional: invitationCode,
  },
  {
    tag: 'input',
    type: 'text',
    name: 'relationshipLength',
    questionText: `“To love oneself is the beginning of a lifelong romance” wrote Oscar Wilde.&&This assessment is most helpful when you are in a relationship.&&Come back when you are in one. :)`,
    conditional: 'Not in a romantic relationship',
  },
  {
    tag: 'input',
    type: 'radio',
    name: 'relationshipSatisfaction',
    questionText: `Amazing. Let's get to it.&&We will ask you two general questions about your relationship.&&What is your current level of happiness and satisfaction in your relationship?`,
    options: [
      `Not satisfied at all - I'd like to see a major change`,
      `Somewhat - Ups and downs but I'd like to see some improvement`,
      `I am mostly happy and satisfied but would still benefit from further improvement and excitement`,
      `I am completely satisfied and like to keep it this way`,
    ],
    conditionalName: "invitationCodeEntry",
    conditional: invitationCode,
  },
  {
    tag: 'input',
    type: 'radio',
    name: 'negativeInteractions',
    questionText: `How often do you experience a "negative interaction" in your relationship?&&We define "negative interaction" as any conflict, argument, problem or issue.`,
    options: [
      'Daily',
      'Many times a week',
      'Once or twice a week',
      'Few times a month',
      'Few times a year',
      'Once a year or less often',
    ],
    conditionalName: "invitationCodeEntry",
    conditional: invitationCode,
  },
  {
    tag: 'input',
    type: 'checkbox',
    name: 'problems',
    questionText: `Now, we will briefly explore any problems you might be having in your relationship.&&In the past month, have you experienced an issue in any of the following areas? Select all that apply (if any).`,
    options: problemsOptions,
    conditionalName: "invitationCodeEntry",
    conditional: invitationCode,
  },
  {
    tag: 'input',
    type: 'radio',
    name: 'howEnjoyable',
    questionText: `Ok, let’s talk about the positives.&&When you reflect on the following questions, it is important that you consider only the positive qualities of your relationship and ignore the negative ones.&&How “enjoyable” do you feel your relationship is?`,
    options: ['Not at all', 'A tiny bit', 'A little', 'Somewhat', 'Mostly', 'Very extremely'],
    conditionalName: "invitationCodeEntry",
    conditional: invitationCode,
  },
  {
    tag: 'input',
    type: 'radio',
    name: 'howPleasant',
    questionText: `“My relationship is pleasant.” How true does this statement sound?`,
    options: ['Not at all', 'A tiny bit', 'A little', 'Somewhat', 'Mostly', 'Very extremely'],
    conditionalName: "invitationCodeEntry",
    conditional: invitationCode,
  },
  {
    tag: 'input',
    type: 'radio',
    name: 'howStrong',
    questionText: `“My relationship is strong.” How true does this statement sound?`,
    options: ['Not at all', 'A tiny bit', 'A little', 'Somewhat', 'Mostly', 'Very extremely'],
    conditionalName: "invitationCodeEntry",
    conditional: invitationCode,
  },
  {
    tag: 'input',
    type: 'radio',
    name: 'howAlive',
    questionText: `How “alive” do you feel your relationship is?`,
    options: ['Not at all', 'A tiny bit', 'A little', 'Somewhat', 'Mostly', 'Very extremely'],
    conditionalName: "invitationCodeEntry",
    conditional: invitationCode,
  },
  {
    tag: 'input',
    type: 'radio',
    name: 'howMiserable',
    questionText: `Almost done!&&In this final part, we will also explore the not-so-positive aspects of your relationship.&&When you reflect on the following questions, consider only the negative qualities of your relationship and ignore the positive ones.&&“My relationship is miserable.”  How true does this statement sound?`,
    options: ['Not at all', 'A tiny bit', 'A little', 'Somewhat', 'Mostly', 'Very extremely'],
    conditionalName: "invitationCodeEntry",
    conditional: invitationCode,
  },
  {
    tag: 'input',
    type: 'radio',
    name: 'howBad',
    questionText: `“My relationship is bad.”  How true does this statement sound?`,
    options: ['Not at all', 'A tiny bit', 'A little', 'Somewhat', 'Mostly', 'Very extremely'],
    conditionalName: "invitationCodeEntry",
    conditional: invitationCode,
  },
  {
    tag: 'input',
    type: 'radio',
    name: 'howEmpty',
    questionText: `How “empty” do you feel your relationship is?`,
    options: ['Not at all', 'A tiny bit', 'A little', 'Somewhat', 'Mostly', 'Very extremely'],
    conditionalName: "invitationCodeEntry",
    conditional: invitationCode,
  },
  {
    tag: 'input',
    type: 'radio',
    name: 'howLifeless',
    questionText: `“My relationship is lifeless.”  How true does this statement sound?`,
    options: ['Not at all', 'A tiny bit', 'A little', 'Somewhat', 'Mostly', 'Very extremely'],
    conditionalName: "invitationCodeEntry",
    conditional: invitationCode,
  },
  {
    tag: 'input',
    type: 'radio',
    name: 'emailScreening',
    questionText: `Well done. We are done.&&Give us a moment while we analyse your answers and you will be taken to the results page.&&Would you like a more in-depth analysis by our expert in your email?&&This will help improve your relationship. (And don’t worry, we hate spam as much as you do.)`,
    options: ['Yes, send me an in-depth analysis too', 'No, just show me my results'],
    conditionalName: "invitationCodeEntry",
    conditional: invitationCode,
  },
  {
    tag: 'input',
    type: 'email',
    name: 'email',
    questionText: 'What is your email address?',
    conditional1: 'Yes, send me an in-depth analysis too',
    conditionalName1: "emailScreening",
    conditional2: invitationCode,
    conditionalName2: "invitationCodeEntry",
  },
];
