import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { ConversationalForm } from 'conversational-form';

import ResultScreen from './resultScreen';
import { questions } from './assessmentQuestions';
import {
  parseQuestions,
  getPositiveAndNegativeScores,
  attachAnswerButtonListeners,
  makeSessionId,
  submitEmailThroughForm,
  submitInviteEmail,
} from './helpers';

import classes from './relationshipAssessment.module.scss';

const CHAINED_RESPONSE_TIME = 1250;
let _sessionid = null;

const RelationshipAssessment = () => {
  const [sessionId, setSessionId] = useState('');
  const [email, setEmail] = useState(null);
  const [isAssessmentDone, setIsAssessmentDone] = useState(false);
  const [positiveAndNegativeScores, setPositiveAndNegativeScores] = useState({ positivesScore: 0, negativesScore: 0 });

  const formFields = parseQuestions(questions);

  const submitCallback = async function () {
    const formDataSerialized = this.getFormData(true);
    const email = formDataSerialized && formDataSerialized.email ? formDataSerialized.email : null;
    const invitationCodeRequestEmail = formDataSerialized && formDataSerialized.invitationCodeRequestEmail ? formDataSerialized.invitationCodeRequestEmail : null;
    
    if (email && email.length !== 0 && email !== '' && email.indexOf('@') !== -1) {
      setEmail(email);
      submitEmailThroughForm(_sessionid, email);
    } else if (invitationCodeRequestEmail && invitationCodeRequestEmail.length !== 0 && invitationCodeRequestEmail !== '' && invitationCodeRequestEmail.indexOf('@') !== -1) {
      submitInviteEmail(_sessionid, invitationCodeRequestEmail);
    }

    if (!formDataSerialized.howLifeless || formDataSerialized.howLifeless == "") {
      console.log("Assessment not completed, user might have requested invite email");

      if (invitationCodeRequestEmail && invitationCodeRequestEmail.length !== 0 && invitationCodeRequestEmail !== '' && invitationCodeRequestEmail.indexOf('@') !== -1) {
        this.addRobotChatResponse(`Great!&&Hold tight while we enable the service for more users.&&See you soon.`);
      }

      return;
    }
    
    this.addRobotChatResponse(`Great!&&Hold tight while we analyse your answers.&&...`);

    // Have to do this because the timeout immediately executes
    // Does not wait for robot chat response to finish
    const resultScreenTimeoutLength = CHAINED_RESPONSE_TIME * 6 + 2000;

    setTimeout(() => {
      const positiveAndNegativeScores = getPositiveAndNegativeScores(formDataSerialized);

      setPositiveAndNegativeScores(positiveAndNegativeScores);

      setIsAssessmentDone(true);

      if (typeof window !== `undefined`) {
        gtag('event', 'assessment_completed', {
          event_category: '',
          event_label: '',
          non_interaction: true,
        });
      }
    }, resultScreenTimeoutLength);
  };

  const assessmentRef = useRef(null);

  // Only run on first render
  useEffect(() => {
    let isMounted = true;

    const newSessionId = makeSessionId(16);
    setSessionId(newSessionId);
    _sessionid = newSessionId;

    attachAnswerButtonListeners(newSessionId);

    axios.get('https://heartspacerelweb.herokuapp.com/isAlive').then((resp) => {
      if (!resp || resp.status !== 200) {
        if (typeof window !== `undefined`)
          gtag('event', 'assessment_isAlive_failure', {
            event_category: '',
            event_label: '',
            non_interaction: true,
          });
      } else {
        if (typeof window !== `undefined`)
          gtag('event', 'assessment_isAlive_success', {
            event_category: '',
            event_label: '',
            non_interaction: true,
          });
      }
    });

    const cf = ConversationalForm.startTheConversation({
      options: {
        submitCallback,
        theme: 'light',
        showProgressBar: true,
        preventAutoFocus: true,
        userInterfaceOptions: {
          controlElementsInAnimationDelay: 1250,
          robot: {
            robotResponseTime: 0,
            chainedResponseTime: CHAINED_RESPONSE_TIME,
          },
        },
      },
      tags: formFields,
    });

    if (isMounted) assessmentRef.current.appendChild(cf.el);

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className={classes.container}>
      <div ref={assessmentRef} style={{ display: isAssessmentDone ? 'none' : 'inherit', transition: 'all .2s' }} />
      {isAssessmentDone ? (
        <ResultScreen
          sessionId={sessionId}
          email={email}
          positiveAndNegativeScores={positiveAndNegativeScores}
          style={{ display: 'flex' }}
        />
      ) : null}
    </div>
  );
};

export default RelationshipAssessment;
